import ChatwootWidget from '@/common/ChatwootWidget';
import '@/styles/globals.css';
import MiscUtils from '@/utils/MiscUtils';
import * as snippet from '@segment/snippet';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Persister, PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';
import { NextPage } from 'next';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import Script from 'next/script';
import { ReactElement, ReactNode } from 'react';

// Initializing DayJS

dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);

dayjs.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'a few seconds',
    m: '1m',
    mm: '%dm',
    h: '1h',
    hh: '%dh',
    d: '1d',
    dd: '%dd',
    M: '1mo',
    MM: '%dmo',
    y: '1y',
    yy: '%dy',
  },
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  },
});

let persister: Persister;

if (MiscUtils.isBrowser()) {
  persister = createSyncStoragePersister({ storage: window.localStorage });
}

function renderSnippet() {
  const opts = {
    apiKey: process.env.NEXT_PUBLIC_ANALYTICS_WRITE_KEY,
    // note: the page option only covers SSR tracking.
    // Page.js is used to track other events using `window.analytics.page()`
    page: true,
  };

  if (process.env.NODE_ENV === 'development') {
    return snippet.max(opts);
  }

  return snippet.min(opts);
}

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

export default function App({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? ((page) => page);

  return (
    <>
      <Head>
        <title>Bloom - Learn to Invest</title>

        {/* <!-- Favicon --> */}
        <link rel="icon" type="image/svg+xml" href="favicon.ico" />

        {/* <!-- Primary Meta Tags --> */}
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="title" content="Bloom - Learn to Invest" />
        <meta
          name="description"
          content="Bloom is a stock investing app for teens under 18. With built in parental controls, education modules, fractional trading and more, Bloom offers the safest way for teens to start building wealth early."
        />
        <meta
          name="keywords"
          content="Bloom, Investing for teens, Investing for beginners, how to invest, learn to invest, how the market works, crypto, how to day trade, what is a stock, what is the stock market."
        />
        <meta name="robots" content="index, follow" />
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <meta name="author" content="Bloom Investment Advisers, LLC" />

        {/* <!-- Open Graph / Facebook --> */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={process.env.NEXT_PUBLIC_APP_URL} />
        <meta property="og:title" content="Bloom - Learn to Invest" />
        <meta
          property="og:description"
          content="Bloom is an investing app that teaches you how to invest. We offer 300+ lessons about stocks, ETFs and more, as well as a real investing account that you can use to start building wealth."
        />
        <meta
          property="og:image"
          content={`${process.env.NEXT_PUBLIC_CDN_URL}/images/banner.png`}
        />

        {/* <!-- Twitter --> */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={process.env.NEXT_PUBLIC_APP_URL} />
        <meta property="twitter:title" content="Bloom - Learn to Invest" />
        <meta
          property="twitter:description"
          content="Bloom is a stock investing app for teens under 18. With built in parental controls, education modules, fractional trading and more, Bloom offers the safest way for teens to start building wealth early."
        />
        <meta
          property="twitter:image"
          content={`${process.env.NEXT_PUBLIC_CDN_URL}/images/banner.png`}
        />

        {/* <!-- Apple Touch Icons --> */}
        <link rel="apple-touch-icon" href="apple-touch-icon.png" />

        <link
          rel="preload"
          href={`${process.env.NEXT_PUBLIC_CDN_URL}/fonts/SF-Pro-Rounded-Regular.woff2`}
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href={`${process.env.NEXT_PUBLIC_CDN_URL}/fonts/SF-Pro-Rounded-Medium.woff2`}
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href={`${process.env.NEXT_PUBLIC_CDN_URL}/fonts/SF-Pro-Rounded-Semibold.woff2`}
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href={`${process.env.NEXT_PUBLIC_CDN_URL}/fonts/SF-Pro-Rounded-Bold.woff2`}
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
      </Head>
      <ChatwootWidget />
      <Script id="rudderstack">
        {`rudderanalytics=window.rudderanalytics=[];for(var methods=["load","page","track","alias","group","identify","ready","reset"],i=0;i<methods.length;i++){var method=methods[i];rudderanalytics[method]=function(d){return function(){rudderanalytics.push([d,...arguments])}}(method)}rudderanalytics.load("2Psr6La1hzk3I7DuufuvJJSh5jJ","https://rudderstack.joinbloom.co"),rudderanalytics.page();`}
      </Script>
      <Script id="rudderstack-cdn" src="https://cdn.rudderlabs.com/v1/rudder-analytics.min.js" />
      <Script id="segment-script" dangerouslySetInnerHTML={{ __html: renderSnippet() }} />
      <PersistQueryClientProvider client={queryClient} persistOptions={{ persister }}>
        {getLayout(<Component {...pageProps} />)}
        <ReactQueryDevtools initialIsOpen={false} />
      </PersistQueryClientProvider>
    </>
  );
}
