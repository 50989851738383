namespace MiscUtils {
  export function prependKeysToObject<T extends Record<string, unknown>>(
    obj: T,
    keyToPrepend: string
  ): T {
    const ret: Record<string, any> = {};

    Object.keys(obj).map((key: string) => {
      ret[keyToPrepend + key] = obj[key];
    });

    return ret as T;
  }

  export function generateUUID() {
    var d = new Date().getTime();
    var d2 =
      (typeof performance !== 'undefined' && performance.now && performance.now() * 1000) || 0;
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16;
      if (d > 0) {
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
  }

  export function isBrowser() {
    return typeof window !== "undefined";
  } 

}

export default MiscUtils;
